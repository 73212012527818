<template>
    <div id="management">
        <div class="top-search" @click="$router.push({path: '/searchProduct'})">
            <div class="search-box">
                <img src="@images/search.png" alt/>
                <input type="text" readonly placeholder="搜索商品名称或关键词"/>
            </div>
        </div>

        <div class="inner-box">
            <div class="nav-box">
                <div :class="firstNav === index ? 'nav-item active' : 'nav-item'"
                     @click="firstMenu(item.id, index)"
                     v-for="(item, index) in secondCategory"
                     :key="index">
                    <span>{{item.category_name}}</span>
                </div>
            </div>

            <div class="info-box">
                <!-- 二级分类 -->
                <div class="second-menu-container" ref="secondMenuContainer">
                    <ul class="list">
                        <li
                            :class="secondNav === index ? 'item active' : 'item'"
                            @click="secondMenu(item.id, index, true)"
                            v-for="(item, index) in firstCategory"
                            :key="index"
                        >{{item.category_name}}
                        </li>
                    </ul>
                </div>
                <!-- 商品列表 -->
                <div class="goods-container" ref="goodsList">
                    <ul class="list">
                        <li class="item"
                            v-for="(item, index) in categoryList"
                            :key="index"
                            @click="handleProduct(item)">
                            <img v-lazy="item.product_cover" alt/>
                            <img class="out" src="@images/sell-out.png" alt v-if="item.product_status === '0'"/>
                            <img class="out" src="@images/edit-fail.png" alt v-if="item['is_examine'] === '2'"/>
                            <div>
                                <p class="title">{{item.product_name}} {{item.specifications}} {{item.unit}}</p>
                                <div class="bottom">
                                    <span
                                        class="price">￥{{item.vip_price > 0 ? item.vip_price : item.original_price}}</span>
                                    <span class="btn" v-if="item['is_examine'] === '1'">设置</span>
                                    <span class="status" v-if="item['is_examine'] === '0'">审核中</span>
                                    <span
                                        class="status"
                                        v-if="item['is_examine'] === '2'"
                                        @click="$router.push({path: '/commodityOperate', query: {id: item.id, type:'edit'}})"
                                    >重新编辑</span>
                                </div>
                            </div>
                        </li>
                        <li><p class="bottom-tips" v-if="isEmpty && subMenuScroller">别划了，到底儿啦</p></li>
                    </ul>
                </div>
            </div>
        </div>

        <img
            class="add-product"
            @click="isAddShow = true"
            src="@images/add-card.png"
            alt
        />
        <van-popup
            class="active-view"
            v-model="isActiveShow"
            position="bottom"
            :style="{ height: '233px' }"
        >
            <div class="handle-item">
                <span @click="isActiveShow = false">取消</span>
                <span class="active" @click="editActive">完成</span>
            </div>
            <div class="handle-item">
                <span>特价商品</span>
                <van-switch active-color="#FF6737" v-model="specialSwitch" size="20"/>
            </div>
            <div class="handle-item">
                <span>店长推荐</span>
                <van-switch active-color="#FF6737" v-model="activeSwitch" size="20"/>
            </div>
        </van-popup>

        <van-popup class="position-view" v-model="isPositionShow" position="bottom">
            <div
                class="handle-item"
                @click="editGoodsInfo"
            >编辑详情
            </div>
            <div
                class="handle-item"
                @click="handleSellOut"
            >{{productInfo.product_status === 0 ? '恢复供货' : '售罄'}}
            </div>
            <div class="handle-item" @click="handleActive">修改活动状态</div>
            <div class="handle-item active" @click="handleDismount">下架</div>
            <div class="handle-item" @click="isPositionShow = false">取消</div>
        </van-popup>

        <van-overlay :show="isAddShow" @click="isAddShow = false">
            <div class="add-wrapper" @click.stop>
                <div class="add-title">请选择添加方式</div>
                <div
                    class="add-item"
                    @click="$router.push({path: '/commodityOperate', query: {type: 'add'}})"
                >
                    <span>手动添加</span>
                    <img src="@images/arrow-nav.png" alt/>
                </div>
                <div class="add-item" @click="$router.push({path:'/importProduct'})">
                    <span>从商品库导入</span>
                    <img src="@images/arrow-nav.png" alt/>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import {
        firstMenuApi,
        getSingleGoodsInfo,
        handleActiveProduct,
        handleSpecialProduct,
        initGoodsList,
        productDelete,
        productDismount,
        productSellOut,
        secondMenuApi,
    } from "../api/requist";
    import BScroll from "better-scroll";

    export default {
        components: {},
        props: [],
        data() {
            return {
                isAddShow: false,
                specialSwitch: false,
                activeSwitch: false,
                isActiveShow: false,
                productInfo: {},
                isPositionShow: false,
                firstNav: 0,
                secondNav: 0,
                firstMenuScroller: null, // 一级分类BS实例
                subMenuScroller: null, // 二级分类BS实例
                goodsListScroller: null, // 商品列表BS实例
                firstCategory: [],
                categoryList: [],
                secondCategory: [],
                pageSize: 15, // 一级分类返回的数据默认页容量为15，改为其他数字会导致直接加载完成
                pageCurrent: 2,
                categorySubsetId: "", // 二级菜单id
                isEmpty: false, //是否到底了
                tempCurrentEditedGoodsId: '-1', // 修改商品时临时记录的被修改商品ID
            }
        },
        mounted() {
            this.getList();
        },
        activated() {
            let _that = this;
            _that.isPositionShow = false;
            if (_that.tempCurrentEditedGoodsId !== '-1') {
                Toast.loading({
                    message: '正在加载更新',
                    forbidClick: true,
                });
                // 如果当前用户是从修改商品界面返回的，则去更新这个商品的信息
                let formData = new FormData();
                formData.append('product_id', _that.tempCurrentEditedGoodsId);
                formData.append('merch_id', localStorage.getItem("merch_id"));
                getSingleGoodsInfo(formData).then(res => {
                    if (res.code === 200) {
                        Toast.success('更新成功');
                        for (let i = 0; i < _that.categoryList.length; i++) {
                            if (_that.categoryList[i].id === _that.tempCurrentEditedGoodsId) {
                                console.log(_that.categoryList[i]);
                                _that.categoryList[i] = res.data;
                                _that.tempCurrentEditedGoodsId = '-1';
                                console.log(_that.categoryList[i]);
                                this.$forceUpdate();
                                break;
                            }
                        }
                    } else {
                        Toast.fail('更新失败');
                        Toast(res.msg);
                    }
                })
            }
        },
        methods: {
            /**
             * 修改编辑商品信息
             */
            editGoodsInfo: function () {
                this.tempCurrentEditedGoodsId = this.productInfo.id;
                this.$router.push({path: '/commodityOperate', query: {id: this.productInfo.id, type: 'edit'}});
            },
            dialog(title, message, confirm, cancel, backFn) {
                Dialog.confirm({
                    confirmButtonText: confirm,
                    cancelButtonText: cancel,
                    confirmButtonColor: "#FF6737",
                    cancelButtonColor: "#C0C4CC",
                    title: title,
                    message: message,
                })
                    .then(() => {
                        backFn();
                    })
                    .catch(() => {
                    });
            },

            handleActive() {
                this.isPositionShow = false;
                this.specialSwitch = this.productInfo.is_special_price !== "0";
                this.activeSwitch = this.productInfo.is_recommend !== "0";
                this.isActiveShow = true;
            },

            async editActive() {
                let sFormData = new FormData();
                sFormData.append("merch_id", localStorage.getItem("merch_id"));
                sFormData.append("product_id", this.productInfo.id);
                sFormData.append("is_special_price", this.specialSwitch ? '1' : '0');
                await handleSpecialProduct(sFormData);

                let aFormData = new FormData();
                aFormData.append("merch_id", localStorage.getItem("merch_id"));
                aFormData.append("product_id", this.productInfo.id);
                aFormData.append("is_recommend", this.activeSwitch ? '1' : '0');
                await handleActiveProduct(aFormData);
                this.$nextTick(() => {
                    Toast("修改完成");
                    this.categoryList.forEach((item) => {
                        if (item.id === this.productInfo.id) {
                            item.is_recommend = this.activeSwitch ? '1' : '0';
                            item.is_special_price = this.specialSwitch ? '1' : '0';
                        }
                    });
                    this.isActiveShow = false;
                });
            },

            handleDelete() {
                let that = this;
                this.isPositionShow = false;
                this.dialog(
                    "",
                    "删除前请先确保此商品无为完成订单并且删除后将无法恢复",
                    "确认删除",
                    "再想一想",
                    function () {
                        let formData = new FormData();
                        formData.append("merch_id", localStorage.getItem("merch_id"));
                        formData.append("product_id", that.productInfo.id);
                        productDelete(formData).then((res) => {
                            if (res.code === 200) {
                                let newList = [];
                                that.categoryList.forEach((item) => {
                                    if (item.id !== that.productInfo.id) {
                                        newList.push(item);
                                    }
                                });
                                that.categoryList = newList;
                                Toast("删除成功");
                            } else {
                                that.dialog(
                                    "删除失败",
                                    "请先去处理包含此商品的未完成订单",
                                    "立即处理",
                                    "稍后处理",
                                    function () {
                                        that.$router.push({
                                            path: "/",
                                        });
                                    }
                                );
                            }
                        });
                    }
                );
            },
            //下架
            handleDismount() {
                let that = this;
                this.isPositionShow = false;
                this.dialog(
                    "",
                    "下架后用户将无法继续购买此商品，确认要继续下架吗？",
                    "确认下架",
                    "再想一想",
                    function () {
                        let formData = new FormData();
                        formData.append("merch_id", localStorage.getItem("merch_id"));
                        formData.append("product_id", that.productInfo.id);
                        formData.append("is_recycle", 1);
                        productDismount(formData).then((res) => {
                            if (res.code === 200) {
                                that.categoryList.forEach((item, index) => {
                                    if (item.id === that.productInfo.id) {
                                        that.categoryList.splice(index, 1);
                                    }
                                });

                                Toast("下架成功");
                            } else {
                                that.dialog(
                                    "下架失败",
                                    "请先去处理包含此商品的未完成订单",
                                    "稍后处理",
                                    "立即处理",
                                    function () {
                                        that.$router.push({
                                            path: "/",
                                        });
                                    }
                                );
                            }
                        });
                    }
                );
            },
            //售罄
            handleSellOut() {
                let that = this;
                if (this.productInfo.product_status === 0) {
                    let formData = new FormData();
                    formData.append("merch_id", localStorage.getItem("merch_id"));
                    formData.append("product_id", that.productInfo.id);
                    formData.append("product_status", 1);
                    productSellOut(formData).then((res) => {
                        if (res.code === 200) {
                            that.categoryList.forEach((item) => {
                                if (item.id === that.productInfo.id) {
                                    item.product_status = 1;
                                }
                            });
                            Toast("恢复供货成功");
                        } else {
                            Toast("恢复供货失败");
                        }
                    });
                } else {
                    this.dialog(
                        "",
                        "售罄前请先确保此商品无未完成订单之后可通过菜单恢复上架",
                        "确认售罄",
                        "再想一想",
                        function () {
                            let formData = new FormData();
                            formData.append("merch_id", localStorage.getItem("merch_id"));
                            formData.append("product_id", that.productInfo.id);
                            formData.append("product_status", 0);
                            productSellOut(formData).then((res) => {
                                if (res.code === 200) {
                                    that.categoryList.forEach((item) => {
                                        if (item.id === that.productInfo.id) {
                                            item.product_status = 0;
                                        }
                                    });
                                    Toast("售罄成功");
                                } else {
                                    that.dialog(
                                        "售罄失败",
                                        "请先去处理包含此商品的未完成订单",
                                        "立即处理",
                                        "稍后处理",
                                        function () {
                                            that.$router.push({
                                                path: "/",
                                            });
                                        }
                                    );
                                }
                            });
                        }
                    );
                }
                this.isPositionShow = false;
            },
            handleProduct(item) {
                if (item["is_examine"] !== '1') return;
                this.productInfo = item;
                this.isPositionShow = true;
            },
            async getList() {
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                let res = await initGoodsList(formData);
                if (res.code === 200) {
                    this.firstCategory = res.data.SubsetList;
                    this.secondCategory = res.data.ProductCategoryList;
                    this.categoryList = res.data.ProductList;
                    this.categorySubsetId = res.data.SubsetList[0].id;
                    this.isEmpty = this.categoryList.length < this.pageSize;
                }
                this.$nextTick(() => {
                    if (!this.subMenuScroller) {
                        this.subMenuScroller = new BScroll(this.$refs.secondMenuContainer, {
                            scrollY: false,
                            taps: false,
                            scrollX: true,
                            mouseWheel: true,
                            click: true,
                            bounceTime: 200,
                        });
                    } else {
                        this.subMenuScroller.refresh()
                    }
                    if (!this.goodsListScroller) {
                        this.goodsListScroller = new BScroll(this.$refs.goodsList, {
                            bounce: {
                                top: true,
                                left: false,
                                right: false,
                                bottom: false,
                            },
                            scrollY: true,
                            scrollX: false,
                            mouseWheel: true,
                            click: true,
                            taps: true,
                            pullUpLoad: {
                                threshold: 50,
                            },
                        });
                        this.goodsListScroller.on("pullingUp", () => {
                            this.secondMenu(this.categorySubsetId);
                            this.goodsListScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
                        });
                    } else {
                        this.goodsListScroller.refresh()
                    }
                });
            },

            async firstMenu(categoryId, index) {
                console.log('firstMenu');
                Toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                });
                this.secondNav = 0;
                this.firstCategory = [];
                this.categoryList = [];
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                formData.append("category_id", categoryId);
                index !== "" ? (this.firstNav = index) : "";
                let res = await firstMenuApi(formData);
                if (res.code === 200) {
                    this.firstCategory = res.data.SubsetList;
                    this.categoryList = res.data.ProductList;

                    // 判断当前列表商品是否加载完成
                    this.isEmpty = this.categoryList.length < this.pageSize;
                    // 重置页码为1
                    this.pageCurrent = 2;
                    // 设置切换分类后当前默认二级分类的全部id集合
                    this.categorySubsetId = this.firstCategory[0].id;

                    if (!this.subMenuScroller) {
                        this.subMenuScroller = new BScroll(this.$refs.secondMenuContainer, {
                            startX: 0,
                            scrollY: false,
                            taps: false,
                            scrollX: true,
                            mouseWheel: true,
                            click: true,
                            bounceTime: 200,
                        });
                    } else {
                        this.subMenuScroller.refresh();
                    }
                    Toast.clear();
                }
            },

            async secondMenu(categorySubId, index, flag) {
                console.log('secondMenu');
                if (this.categorySubsetId !== categorySubId || flag) {
                    this.isEmpty = false;
                    this.pageSize = 10;
                    this.pageCurrent = 1;
                    this.categoryList = [];
                }

                if (this.isEmpty) return;

                this.goodsListScroller.closePullUp();
                Toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                });
                index !== "" && index !== undefined
                    ? (this.secondNav = index)
                    : "";

                this.categorySubsetId = categorySubId;
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                formData.append("category_subset_id", this.categorySubsetId);
                formData.append("pageSize", this.pageSize);
                formData.append("pageCurrent", '' + this.pageCurrent++);
                let res = await secondMenuApi(formData);
                if (res.code === 200) {
                    this.goodsListScroller.refresh();
                    this.categoryList = [...this.categoryList, ...res.data.ProductList];
                    if (res.data.ProductList.length) {
                        this.pageCurrent = this.pageCurrent++;
                        this.isEmpty = false;
                    } else {
                        this.isEmpty = true;
                    }
                    if (this.categoryList.length < this.pageSize) {
                        this.isEmpty = true;
                    }
                    this.goodsListScroller.openPullUp();
                    Toast.clear();
                }
            },
        },
    };
</script>
<style lang="scss">
    .van-dialog {
        border-radius: 6px;
    }

    #management {
        min-height: 100vh;
        width: 100%;

        .van-overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
        }

        .add-wrapper {
            border-radius: 6px;
            width: 313px;
            background-color: #fff;

            .add-title {
                height: 45px;
                line-height: 45px;
                padding-left: 21px;
                box-sizing: border-box;
                color: rgba(48, 49, 51, 1);
                font-size: 14px;
                text-align: left;
            }

            .add-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 0 13px 0 21px;

                &:nth-child(2) {
                    border: 1px solid rgba(242, 246, 252, 1);
                }

                span {
                    color: rgba(48, 49, 51, 1);
                    font-size: 16px;
                }

                img {
                    height: 12px;
                    width: 6px;
                }
            }
        }

        .add-product {
            z-index: 9;
            position: fixed;
            height: 45px;
            width: 45px;
            right: 24px;
            bottom: 40px;
        }

        .top-search {
            height: 44px;
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(240, 242, 245, 1);
            display: flex;
            align-items: center;

            .search-box {
                width: 100%;
                height: 32px;
                border: 1px solid #cfd2d8;
                background: rgba(245, 247, 250, 1);
                opacity: 1;
                border-radius: 15px;
                display: flex;
                align-items: center;
                padding: 0 12px;
                box-sizing: border-box;

                img {
                    height: 14px;
                    width: 14px;
                    margin-right: 8px;
                }

                input {
                    color: #303133;
                    width: 100%;
                    height: 100%;
                    font-size: 14px;
                    border: none;
                    outline: none;
                    box-sizing: border-box;
                    background: rgba(245, 247, 250, 1);

                    &::placeholder {
                        font-size: 14px;
                        color: rgba(192, 196, 204, 1);
                    }
                }
            }
        }

        .nav-box {
            width: 94px;
            height: calc(100vh - 44px);
            border-right: 1px solid rgba(240, 242, 245, 1);

            .nav-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 44px;
                line-height: 44px;
                text-align: center;
                color: rgba(48, 49, 51, 1);
                font-size: 14px;

                &.active {
                    span {
                        display: block;
                        width: 78px;
                        height: 28px;
                        line-height: 28px;
                        background: rgba(255, 103, 55, 1);
                        border-radius: 14px;
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }

        .inner-box {
            display: flex;
        }

        .info-box {
            padding: 12px 0 0 6px;
            flex: 1;
            height: calc(100vh - 44px);
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            width: 0;

            .second-menu-container {
                overflow: hidden;
                height: 24px;
                white-space: nowrap;
                margin-bottom: 24px;

                .list {
                    float: left;
                    white-space: nowrap;
                    height: 24px;
                    display: inline-block;
                }

                .item {
                    display: inline-block;
                    margin: 0 6px;
                    height: 24px;
                    padding: 0 8px;
                    background: rgba(245, 247, 250, 1);
                    border: 1px solid #cfd2d8;
                    border-radius: 2px;
                    font-size: 14px;
                    color: rgba(48, 49, 51, 1);
                    line-height: 24px;
                    box-sizing: border-box;

                    &:last-child {
                        margin-right: 12px;
                    }

                    &.active {
                        color: #ff6737;
                        background: rgba(255, 103, 55, 0.2);
                        border: 1px solid rgba(255, 103, 55, 1);
                    }
                }
            }

            .goods-container {
                height: calc(100% - 60px);
                overflow: hidden;

                .bottom-tips {
                    width: 100%;
                    display: inline-block;
                    padding: 24px 0;
                    text-align: center;
                    font-size: 12px;
                    color: rgba(192, 196, 204, 1);
                }

                li {
                    position: relative;
                    display: flex;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img {
                        object-fit: cover;
                        height: 80px;
                        width: 80px;
                        margin-right: 8px;
                    }

                    .out {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }

                    & > div {
                        .title {
                            text-align: left;
                            height: 40px;
                            width: calc(100vw - 95px - 6px - 80px - 8px - 16px);
                            line-height: 20px;
                            color: rgba(48, 49, 51, 1);
                            font-size: 14px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }

                        .bottom {
                            margin-top: 18px;
                            display: flex;
                            justify-content: space-between;

                            .price {
                                color: rgba(255, 72, 72, 1);
                                font-size: 16px;
                            }

                            .btn {
                                width: 60px;
                                height: 22px;
                                background: rgba(255, 103, 55, 1);
                                box-shadow: 0 2px 4px rgba(255, 103, 55, 0.16);
                                border-radius: 13px;
                                text-align: center;
                                color: rgba(255, 255, 255, 1);
                                font-size: 12px;
                                line-height: 22px;
                            }

                            .status {
                                font-size: 12px;
                                color: #ff6737;
                            }
                        }
                    }
                }
            }
        }

        .handle-item {
            height: 46px;
            line-height: 46px;
            color: rgba(48, 49, 51, 1);
            font-size: 14px;
            background: rgba(255, 255, 255, 1);

            &:nth-child(1) {
                border-bottom: 1px solid rgba(242, 246, 252, 1);
                border-radius: 4px 4px 0 0;
            }

            &:nth-child(2) {
                border-bottom: 1px solid rgba(242, 246, 252, 1);
            }

            &:nth-child(3) {
                border-bottom: 1px solid rgba(242, 246, 252, 1);
            }

            &.active {
                color: #ff4848;
            }

            &:nth-child(4) {
                border-bottom: 12px solid rgba(242, 246, 252, 1);
            }
        }

        .position-view {
            text-align: center;
            background-color: rgba(0, 0, 0, 0);
        }

        .active-view {
            padding: 0 18px;
            box-sizing: border-box;

            .handle-item {
                height: 46px;
                line-height: 46px;
                color: rgba(48, 49, 51, 1);
                font-size: 14px;
                background: rgba(255, 255, 255, 1);
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    color: rgba(48, 49, 51, 1);
                    font-size: 14px;

                    &.active {
                        color: rgba(255, 103, 55, 1);
                    }
                }

                &:nth-child(1) {
                    border-bottom: 1px solid rgba(242, 246, 252, 1);
                }

                &:nth-child(2) {
                    border-bottom: 1px solid rgba(242, 246, 252, 1);
                }

                // &:nth-child(3) {
                //   border-bottom: 1px solid rgba(242, 246, 252, 1);
                // }
                &.active {
                    color: #ff4848;
                }

                &:nth-child(4) {
                    margin-bottom: 12px;
                }
            }
        }
    }
</style>
